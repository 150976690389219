import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import CartContext from "../contexts/CartContext"

import styles from "./accessoiresItems.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"
import SwiperSlider from "../components/swiperSlider"

const Sacoches = () => {
  const { addItem } = useContext(CartContext)
  const data = useStaticQuery(graphql`
    query {
      sacoches1: allStrapiSacoche(
        filter: { titre: { eq: "Sacoches Vaude Rouge" } }
      ) {
        nodes {
          images {
            url
          }
        }
      }
      sacoches2: file(
        relativePath: { eq: "images/accessoires/sacoches/sacoches2.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sacoches3: file(
        relativePath: { eq: "images/accessoires/sacoches/sacoches3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="saccoches pour vélos électriques"
        description="Sélection des saccoches pour vélos électriques"
      />
      <main className={styles.main}>
        <h1>Sacoches</h1>
        <Link to="/accessoires" className={styles.back}>
          <span>&lt;&lt; accessoires</span>
        </Link>
        <section className={styles.container}>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Sacoches rouge Vaude</h6>
              <p className={styles.price}>120,00 €</p>
            </section>
            <section className={styles.frame}>
              <SwiperSlider data={data.sacoches1} />
            </section>
            <p className={styles.description}>
              A la découverte du monde ! Cette sacoche étanche pour roue
              arrière, en bâche résistante sans PVC, est climatiquement neutre
              et fabriquée sur le site allemand de VAUDE au bord du lac de
              Constance. Elle n’attend que vous pour partir à l’aventure ! Grâce
              à une fermeture par enroulement totalement étanche, sa taille est
              ajustable et son contenu reste bien au sec. Une poche intérieure
              simple permet de bien ranger ses affaires.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Sacoches rouge Vaude",
                  "",
                  1,
                  120.0,
                  "accessoires/sacoches/sacoches1.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Sacoches Randonnée</h6>
              <p className={styles.price}>140,00 €</p>
            </section>
            <section className={styles.frame}>
              <Img
                className={styles.image}
                fluid={data.sacoches2.childImageSharp.fluid}
                alt="..."
              />
            </section>
            <p className={styles.description}>
              Les sacoches arrières Vaude Karakorum Pro, d'une capacité de 65
              litres, sont conçues comme un triple sac de grande capacité pour
              les voyages à vélo. Elles ont deux compartiments principaux de
              chaque côté, avec un compartiment supérieur divisible en 2
              parties, qui peut également être utilisé comme un sac à dos.
              Comprend une multitude de compartiments supplémentaires pour vous
              aider à organiser correctement votre équipement.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Sacoches randonnée",
                  "",
                  1,
                  140,
                  "accessoires/sacoches/sacoches2.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Sacoche verte Ortlieb</h6>
              <p className={styles.price}>90,00 €</p>
            </section>
            <section className={styles.frame}>
              <Img
                className={styles.image}
                fluid={data.sacoches3.childImageSharp.fluid}
                alt="..."
              />
            </section>
            <p className={styles.description}>
              Sportive, sobre et étonnamment pratique, à Marseille comme en
              Mongolie : la Back-Roller Plus d’ORTLIEB est la paire de sacoches
              de roue arrière idéale pour tous ceux qui préconisent la fermeture
              par enroulement très pratique. La toile de Cordura robuste étanche
              à l’eau et à l’aspect d’un tissu précieux, ferme le bagage de
              manière étanche à l’eau sans non plus laisser de chance à la
              poussière.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Sacoche verte Ortlieb ",
                  "",
                  1,
                  90,
                  "accessoires/sacoches/sacoches3.jpg"
                )
              }
              value="Acheter"
            />
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Sacoches
